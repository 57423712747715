@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
    scroll-behavior: smooth;
  }
  
a{
    text-decoration: none;
    color: inherit;
}

.mainContainer{
    width: 100%;
   /* min-height: 100vh; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 120px 80px;
    padding-top: 250px;
    padding-bottom: 250px;
    z-index: -1;
    background: linear-gradient(rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)),url("../../Images/NEWBG.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.homeLeft{
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}


.homeLeft i{
    color: #ffffff;
    border: 2px solid #ffffff;
    background-color: transparent;
    padding: 12px;
    font-size: 18px;
}


.homeRight{
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.homeRight i{
    color: #ffffff;
    border: 2px solid #ffffff;
    background-color: transparent;
    padding: 12px;
    font-size: 18px;
}

.homeLeft img{
    height: 100px;
    object-fit: contain;
}

.homeRight img{
    height: 100px;
    object-fit: contain;
}

.homeCenter{
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.mainContainer img{
    border-radius: 50%;
    width: 100px;
}

.mainContainer h1{
    font-family: "Poppins", sans-serif;
    color: white;
    font-size: 35px;
    text-align: center;
}
.mainContainer h2{
    font-family: "Poppins", sans-serif;
    color: white;
    font-size: 20px;
    text-align: center;
}

.typerWriter{
    font-family: "Poppins", sans-serif;
    color: white;
    font-size: 50px;
    text-align: center;
}

.mainContainer p{
    font-family: "Poppins", sans-serif;
    color: #ffffff;
    text-align: center;
    font-size: 14px;
    width: 80%;
}

.mainContainer .buttons{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.mainContainer .buttons button{
    margin-top: 20px;
    padding: 10px 30px;
    background-color: transparent;
    color: #ffffff;
    border: none;
    /* border-radius: 10px; */
    font-size: 14px;
    box-shadow: rgba(166, 162, 162, 0.29) 0px 5px 15px;
    border-left: 4px solid #ffffff;
    border-right: 4px solid #ffffff;
    cursor: pointer;
}

.mainContainer .buttons button:hover{
    background-color: #ffffff;
    color: #b416f2;
    transition: 0.3s ease-in;
}


@media (max-width: 1200px) {

}


@media (max-width: 971px) {
    .mainContainer h1{
        font-size: 24px;
    }
    
    .mainContainer h2{
        font-size: 16px;
    }
    
    .mainContainer p{
        font-size: 14px;
    }
    .mainContainer .buttons{
        width: 100%;
    }
}


@media (max-width: 768px) {
    .mainContainer{
        flex-direction: column;
        padding: 60px 30px;
        padding-top: 150px;
        padding-bottom: 150px;
        align-items: center;
        justify-content: center;
    }
    .homeLeft{
        display: none;
    }
    .homeRight{
        display: none;
    }
    
.mainContainer h1{
    font-size: 24px;
}

.mainContainer h2{
    font-size: 16px;
}

.mainContainer p{
    font-size: 14px;
}

.mainContainer .buttons{
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding-top: 20px;
}

.mainContainer .buttons button{
    width: 100%;
    margin: 0;
}
    
}
@media (max-width: 480px) {
    .mainContainer p {
        width: 100%;
    }

}