@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
}


.heading{
    font-family: "Poppins", sans-serif;
    color: black;
    font-size: 40px;
    text-align: center;
    margin-top: 50px;
}

.resumeContainer{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  padding: 120px 60px;
    background: linear-gradient(rgba(0, 0, 0, 0.7),
            rgba(16, 15, 15, 0.7)), url("../../Images/NEWBG.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    flex-direction: column;
}

.resumeBox{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.resumeBox form input,textarea{
    padding: 15px;
    color: #b00a0a;
    background-color: rgba(255, 255, 255, 0.169); /* Adjust transparency here */
  backdrop-filter: blur(5px); /* Add background blur */
  border-radius: 10px; /* Adjust as needed */
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0); 
  width: 100%;
  margin-top: 20px;
  border: none;
}
.resumeBox button{
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.169); /* Adjust transparency here */
  backdrop-filter: blur(5px); /* Add background blur */
  border-radius: 10px; /* Adjust as needed */
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0); 
  width: 100%;
  margin-top: 20px;
  border: none;
  cursor: pointer;
}

::placeholder {
    color: #ffffff;
    opacity: 1; /* Firefox */
  }



  @media screen and (max-width: 991px){
    .contactBox1 img {
        width: 100%;
    }
    .contactContainer{
       
    }
    }

    @media screen and (max-width: 767px){
        .contactContainer{
            flex-direction: column;
            padding: 150px 30px;
        }
        .contactBox1 {
            width: 100%;
        }
        .resumeBox{
            width: 100%;
        }
        .contactBox1 img{
            width: 100%;
        }
        }
    @media screen and (max-width: 479px){
        .contactContainer{
            flex-direction: column;
            
        }
        .contactBox1 {
            width: 100%;
        }
        .resumeBox{
            width: 100%;
        }
        .contactBox1 img{
            width: 100%;
        }
        }