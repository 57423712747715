@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
}


.heading {
    font-family: "Poppins", sans-serif;
    color: black;
    font-size: 40px;
    text-align: center;
    margin-top: 50px;
}

.aboutContainer {
    /* height: 100vh; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 120px 60px;
    background: linear-gradient(rgba(0, 0, 0, 0.7),
            rgba(16, 15, 15, 0.7)), url("../../Images/NEWBG.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-20px);
    }

    100% {
        transform: translatey(0px);
    }
}

.aboutBox2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: justify;

    align-items: center;
    margin-top: 30px;
}

.aboutBox2 h1{
    color: #ffffff;
    text-align: center;
    font-size: 25px;
}


.aboutBox2 img{
    width: 100px;
}

.aboutBox2 p{
    color: #ffffff;
    text-align: center;
}

.aboutBox2 .skills{
    padding-top: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.aboutBox2 .skills .box {
    width: 18%;
    height: 150px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.15); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Background blur effect */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Light border to enhance the glass effect */
    display: flex;
    align-items: center;
    justify-content: center;
}


.aboutBox2 .skills .box img{
    height: 80px;
    width: 100%;
    object-fit: contain;
}


.customHeight{
    height: 50px;
}

.git{
    height: 10px;
    width: 100%;
    object-fit: contain;
}


@media (max-width: 1200px) {}

@media (max-width: 971px) {}

@media (max-width: 768px) {

    .aboutContainer{
        padding: 150px 30px;
    }
    .aboutBox2 .skills .box {
        width: 30%;
    }
}

@media (max-width: 480px) {
    .aboutBox2 .skills .box {
        width: 45%;
    }
}