@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Kanit", sans-serif;
}

header {
    width: 100%;
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -15vh;
    position: relative;
    padding: 20px 80px;
}

.logo {
    width: 20%;
    display: flex;
    justify-content: start;
    align-items: center;
}

.logo img {
    width: 60%;
}

.navbar {
    width: 60%;
    display: flex;
    justify-content: space-around;
    /* Changed to space-between */
    align-items: center;

}

.navbar img {
    width: 10%;
}

.navbar ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.navbar ul li {
    list-style-type: none;
}

.navbar ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 10px;
    border-bottom: 3px solid transparent;
}

.navbar ul li a:hover {
    border-bottom: 3px solid #ffffff;
}

.navBtn {
    width: 20%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.navBtn button {
    padding: 10px 30px;
    background-color: transparent;
    color: #ffffff;
    border: none;
    font-size: 14px;
    box-shadow: rgba(255, 254, 254, 0.35) 0px 22px 70px 4px;
    border-left: 4px solid #ffffff;
    border-right: 4px solid #ffffff;
    cursor: pointer;
}


.navBtn button:hover{
    background-color: #ffffff;
    color: #b416f2;
    transition: 0.3s ease-in;
}

.nav-toggle {
    display: none;
    color: #ffffff;
}

.hamburger {
    display: none;
    cursor: pointer;
    font-size: 25px;
    color: #ffffff;
}

.close {
    display: none;
}

.open {
    display: flex;
}


.mobileMenu {
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background: linear-gradient(rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.9)),url("../../Images/NEWBG.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 60vw;
    gap: 20px;
    list-style: none;
    padding: 40px 20px;
    animation:  2s ease-in;
    z-index: 9999999;
}

.mobileMenu button{
    padding: 10px 20px;
    background-color: transparent;
    color: #ffffff;
    border: none;
    font-size: 14px;
    box-shadow: rgba(255, 254, 254, 0.35) 0px 22px 70px 4px;
    border-left: 4px solid #ffffff;
    border-right: 4px solid #ffffff;
    cursor: pointer;
}

.mobileMenu a{
    text-decoration: none;
    color: #fff;
    font-size: 14px;
}

.mobileMenu img{
    width: 80%;
}



/* Responsive styles */


@media (max-width: 1200px) {
    .navbar img {
        display: none;
    }
}

@media (max-width: 971px) {
    .navBtn{
        display: none;
    }
    
    .navbar{
        width: 80%;
        justify-content: end;
    }

    .logo img{
        width: 80%;
    }

}


@media (max-width: 768px) {
    .navbar{
        display: none;
    }
    header{
        justify-content: space-between;
        padding: 20px 30px;
    }
    .hamburger{
        display: block;
    }
    .logo{
        width: 50%;
    }
    .logo img{
        width: 200px;
    }
    
}

@media (max-width: 480px) {}