.footer{
    width: 100%;
    background: rgba(255, 255, 255, 0.02); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Background blur effect */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Light border to enhance the glass effect */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10vh;
    margin-top: -10vh;
    padding: 0 60px;
}


.footer .footerbox1{
    width: 60%;
    display: flex;
    gap: 20px;
}

.footer .footerbox1 li{
    list-style-type: none;
    color: #AD49E1;
    font-size: 14px;
    padding: 10px 0;
    font-weight: 400;
}


.footer .footerbox1 li a{
    color: #ffffff;
    text-decoration: none;
}

.footer .footerbox2{
    width: 100%;
    display: flex;
    justify-content: center;
}


.footer .footerbox2 h3{
    color: #ffffff;
    font-size: 14px;
    text-align: center;
}


@media (max-width: 1200px) {}
@media (max-width: 768px) {
    .main-footer .box1{
        flex-direction: column;
        gap: 20px;
    }
}
@media (max-width: 480px) {
    .main-footer .box1{
        flex-direction: column;
        gap: 10px;
    }
}