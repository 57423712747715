@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
}


.heading {
    font-family: "Poppins", sans-serif;
    color: black;
    font-size: 40px;
    text-align: center;
    margin-top: 50px;
    width: 100%;
}


.heading h1 {
    color: #ffffff;
    font-size: 25px;
}

.heading img {
    width: 100px;
}




.projectsContainer {
    /* height: 100vh; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 120px 80px;
    gap: 40px;
    flex-wrap: wrap;
    background: linear-gradient(rgba(0, 0, 0, 0.7),
            rgba(16, 15, 15, 0.7)), url("../../Images/NEWBG.jpeg");
    background-repeat: repeat-y;
    background-size: contain;
    background-position: top;
}

#projectVideo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* height: auto;  */
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.7);
    filter: brightness(50%);
}

.project {
    width: 45%;
    background-color: rgba(255, 255, 255, 0.078);
    /* Adjust transparency here */
    backdrop-filter: blur(5px);
    /* Add background blur */
    border-radius: 10px;
    /* Adjust as needed */
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.projectWrap{
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 0;
    border-radius: 20px;
    margin-bottom: 40px;
}


.project1 {
    width: 60%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column;
    gap: 0px;
    color: #ffffff;
}

.project1 img{
    width: 100%;
}

.projectContent{
    width: 40%;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.15); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Background blur effect */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #ffffff;
    padding: 40px;
}


.projectContent h2{
    color: #ffffff;
    font-size: 20px;
    text-align: center;
}

.projectContent p{
    text-align: center;
    font-size: 14px;
    text-align: center;
}

.projectContent img{
    width: 100px;
}


.projectContent button{
    padding: 10px 20px;
    border: none;
    color: #ffffff;
    background: transparent;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 100px;
    margin-top: 10px;
}



.project img {
    width: 100%;
    /* height: 200px; */
    object-fit: cover;
}

.projectInfo {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;

}

.projectInfo h2 {
    font-size: 20px;
    color: #ffffff;
}

.projectInfo p {
    font-size: 13px;
    color: #ffffff;
    text-align: center;
}

.projectInfo button {
    padding: 10px 20px;
    border: none;
    color: #ffffff;
    background: transparent;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}



@media (max-width: 1200px) {
    .projectWrap{
        width: 90%;
        flex-direction: column;
    }

    .project1{
        width: 100%;
    }
    .projectContent{
        width: 100%;
    }
    .projectsContainer {
        padding: 150px 30px
    }
}